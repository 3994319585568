import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { Globals } from '../../../../services/globals';
import { CurrentUser } from '../../../../services/user';
import { Currency } from '../../../../services/currency';
import { Utility } from '../../../../services/utility';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import moment from 'moment';


@Component({
    selector: 'journal-entry-dialog',
    templateUrl: './journal-entry-dialog.component.html',
    styleUrls: ['./journal-entry-dialog.component.scss']
})
export class JournalEntryDialogComponent implements OnInit, OnDestroy {
    entry: any;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<JournalEntryDialogComponent>, private translate: TranslateService, public globals: Globals, public currentUser: CurrentUser, public currency: Currency, public utility: Utility) {
    }

    ngOnDestroy() {

    }

    ngAfterViewInit() {
        console.log("ngAfterViewInit");
        this.entry = this.data;
    }

    ngOnInit() {}

    close() {
        this.dialogRef.close();
    }

    openReceipt() {
        if(this.entry.receiptUrl){
            window.open(this.entry.receiptUrl, '_blank');
        }
    }

    getBookingNumber() {
        return this.entry.bookingNumber ? `- #${this.entry.bookingNumber}` : '';
    }

    getDate(date: string) {
        return date && date != '' ? moment(date).format('YYYY-MM-DD') : '';
    }

    getParticipantCount() {
        let count = 0;
        for(let participant of this.entry.participants) {
            count++;
        }
        return count;
    }

    async csvDownload() {

        let download = (content, fileName, mimeType) => {
            var a = document.createElement('a');
            mimeType = mimeType || 'application/octet-stream';

            if ((navigator as any).msSaveBlob) { // IE10
              (navigator as any).msSaveBlob(new Blob([content], {
                    type: mimeType
                }), fileName);
            } else if (URL && 'download' in a) { //html5 A[download]
                a.href = URL.createObjectURL(new Blob([content], {
                    type: mimeType
                }));
                a.setAttribute('download', fileName);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            } else {
                location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
            }
        }

        let getCsvString = (content: string) => {
            if (content && typeof (content) == 'string') {
                var result = content.replace(/"/g, '""');
                return `"${result}"`;
            }
            else {
                return '';
            }
        }

        let csvContent = "";
        csvContent = `#,CREATED_AT,CUSTOMER_NAME,GIFT_CARD_SUM,PAYMENT_DATE,PAYMENT_STATUS,PAYMENT_METHOD,TRANSACTION_DATE,TRANSACTION_STATUS,EXTERNAL_ID,Seq.Id,HANDLED_BY,ABORTED_BY,IS_VERIFIED,PRICE_WITH_VAT,PRICE_WITHOUT_VAT\n`

        let csvArray = [
            getCsvString(String(this.entry.bookingNumber)),
            getCsvString(String(moment(this.entry.created).format("YYYY-MM-DD"))),
            getCsvString(String(this.entry.customer.firstName + " " + this.entry.customer.lastName)),
            getCsvString(String(this.entry.giftCardSum ?? '')),
            getCsvString(String(this.entry.paymentDate)),
            getCsvString(String(this.entry.paymentStatus)),
            getCsvString(String(this.entry.paymentMethod)),
            getCsvString(String(this.entry.transactionInfo.transactionDate ?? '')),
            getCsvString(String(this.entry.transactionInfo.status ?? '')),
            getCsvString(String(this.entry.transactionInfo.externalId ?? '')),
            getCsvString(String(this.entry.transactionInfo.sequenceNumber ?? '')),
            getCsvString(String(this.entry.transactionInfo.finishedBy ?? '')),
            getCsvString(String(this.entry.transactionInfo.abortedBy ?? '')),
            getCsvString(String(this.entry.isVerified)),
            getCsvString(String(this.entry.price.withVat)),
            getCsvString(String(this.entry.price.withoutVat)),
        ]

        //order rows
        let rowIndex = 0;
        for(let row of this.entry.orderRows) {
            csvContent += `ORDER_ROW_NAME_${rowIndex}`
            csvArray.push(getCsvString(String(row.name)))

            csvContent += `ORDER_ROW_ARTICLE_NUMBER_${rowIndex}`
            csvArray.push(getCsvString(String(row.articleNumber)))

            csvContent += `ORDER_ROW_QUANTITY_${rowIndex}`
            csvArray.push(getCsvString(String(row.quantity)))

            csvContent += `ORDER_ROW_WITH_VAT_${rowIndex}`
            csvArray.push(getCsvString(String(row.unitPrice.withVat)))

            csvContent += `ORDER_ROW_WITHOUT_VAT_${rowIndex}`
            csvArray.push(getCsvString(String(row.unitPrice.withoutVat)))

            csvContent += `ORDER_ROW_VAT_${rowIndex}`
            csvArray.push(getCsvString(String(row.unitPrice.vatPercentage)+"%"))

            rowIndex++;
        }

        //payment Info
        let paymentIndex = 0;
        for(let payment of this.entry.paymentInfo) {
            csvContent += `PAYMENT_METHOD_${paymentIndex}`
            csvArray.push(getCsvString(String(payment.provider)))

            csvContent += `PAYMENT_STATUS_${paymentIndex}`
            csvArray.push(getCsvString(String(payment.status)))

            csvContent += `PAYMENT_DATE_${paymentIndex}`
            csvArray.push(getCsvString(String(payment.paymentDate)))

            paymentIndex++;
        }

        csvContent += `${csvArray.join(',')}\r\n`;


        download(csvContent, `journal_data_entry_${this.entry.bookingNumber}.csv`, "data:text/csv;charset=utf-8,")
    }

    openEvent(eventId) {
        let url = `${this.globals.baseUrl}/client/${this.globals.clientId}/events/${eventId}`
        window.open(url);
    }
}
