import { Observable } from "rxjs";

export class FunbutlerEventEmitter {
    callbacks: { [key: string]: Function[] };
    constructor() {
        this.callbacks = {};
    }

    async on(event, cb) {
        if (!this.callbacks[event]) this.callbacks[event] = [];
        this.callbacks[event].push(cb);
    }

    async emit(event, data) {
        let cbs = this.callbacks[event];
        if (cbs) {
            for (let cb of cbs) {
                try {
                    await cb(data);
                } catch (e) {
                    console.log(`Failed to emit event ${event}`);
                    console.log(e);
                }
            }
        }
        return data;
    }
}

export interface ITrackingEvent {
    eventName: string;
    data: any;
}

export interface FunbutlerClientAPI {
    tracking: {
        event: FunbutlerEventEmitter;
    };
}
