import { Component, OnInit, EventEmitter, ViewChild, Inject, Output, Input, ElementRef } from '@angular/core';
import * as  moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Api } from 'projects/client-app/src/app/services/api';
import { IBooking } from 'src/app/interfaces/booking';
import { IQuickResourceBookingFormState } from 'projects/client-app/src/app/interfaces/quick-resource-booking-form-state';
import { Utility } from 'projects/client-app/src/app/services/utility';
import { IResource } from 'projects/client-app/src/app/interfaces/resource';
import { timeDifferenceInMinutes, subtractDurationFromTime, addDurationToTime, orderBy } from 'projects/client-app/src/app/helpers/utility';
import { IResourceBooking } from '../../../../../../../../src/app/interfaces/resource-booking';
import { last } from 'lodash';
import { Globals } from 'projects/client-app/src/app/services/globals';
import { CurrentUser } from 'projects/client-app/src/app/services/user';


interface IResourceToAdd {
  resourceId: string,
  uniqueResourceId: string,
  startTime: string,
  endTime: string,
  lockTimeslots: boolean,
  resourceInfo: {
    resource: IResource,
    timeslots: { [time: string]: { slots: number } },
    startTimes: { time: string, slots: number }[],
    endTimes: {
      time: string,
      slots: number
    }[]
  }
}

@Component({
  selector: 'quick-resource-booking',
  templateUrl: './quick-resource-booking.component.html',
  styleUrls: ['./quick-resource-booking.component.scss']
})
export class QuickResourceBookingComponent implements OnInit {
  @Input()
  resourceBookingToRebook?: IResourceBooking;

  @Input()
  selectedDate: Date = moment().toDate();

  quickResourceBookingState: IQuickResourceBookingFormState = {
    selectedDay: moment().format('YYYY-MM-DD'),
    customerInfo: {
      firstName: '',
      lastName: ''
    },
    hasArrived: false,
    isPaid: false,
    newsletterAccepted: false,
    selectedPersons: 1,
    selectedResources: [],
    price: { vat: 0, vatPercentage: 25, withVat: 0, withoutVat: 0 }
  }
  resourceTimeslots: { [resourceId: string]: any } = {};
  /* resources: {
       [resourceId: string]: {
           resource: IResource,
           timeslots: { [time: string]: { slots: number } },
           startTimes: { time: string, slots: number }[],
           endTimes: {
               time: string,
               slots: number
           }[]
       }
   } = {}; */

  creatingBooking = false;
  availableResources: IResource[];
  resourcesToAdd: IResourceToAdd[] = [];

  @Output()
  resourceBookingCreated = new EventEmitter<IResourceBooking>();

  @ViewChild('staffCommentTextarea', { static: false })
  textArea: ElementRef<HTMLTextAreaElement>;

  constructor(private api: Api, translate: TranslateService, private utility: Utility, public globals: Globals, public currentUser: CurrentUser) {
    this.quickResourceBookingState.customerInfo.firstName = translate.instant('DROP_IN');
  }

  async addResource() {

    let resourceToAdd: IResourceToAdd = {
      resourceId: null,
      uniqueResourceId: this.utility.uuid(),
      startTime: null,
      endTime: null,
      lockTimeslots: false,
      resourceInfo: {
        resource: null,
        timeslots: {},
        startTimes: [],
        endTimes: []
      }
    };



    if (this.resourcesToAdd.length) {
      let lastResourceToAdd = this.resourcesToAdd[this.resourcesToAdd.length - 1];
      let lastIndex = this.availableResources.findIndex(r => {
        return r.id == lastResourceToAdd.resourceId;
      });

      this.resourcesToAdd.push(resourceToAdd);

      console.log('lastIndex', lastIndex);
      if (lastIndex >= 0 && lastIndex < this.availableResources.length - 1) {
        console.log(`Setting new resource to ${this.availableResources[lastIndex + 1].name}`);
        resourceToAdd.resourceId = this.availableResources[lastIndex + 1].id;
        resourceToAdd.resourceInfo.resource = this.availableResources[lastIndex + 1];
        resourceToAdd.startTime = lastResourceToAdd.startTime;
        resourceToAdd.endTime = lastResourceToAdd.endTime;
        resourceToAdd.lockTimeslots = lastResourceToAdd.lockTimeslots;

        await this.refetchTimeslots(resourceToAdd);
      }

    }
    else {
      this.resourcesToAdd.push(resourceToAdd);
    }



  }

  validate() {
    return !this.creatingBooking && this.validateResourcesToAdd();
  }


  getResources() {
    return this.availableResources;
  }

  removeResourceToAdd(resourceToAdd) {
    this.resourcesToAdd.splice(this.resourcesToAdd.indexOf(resourceToAdd), 1);
  }

  validateResourcesToAdd() {
    for (let resourceToAdd of this.resourcesToAdd) {
      if (!resourceToAdd.resourceId || !resourceToAdd.startTime || !resourceToAdd.endTime) {
        return false;
      }
    }
    return true;
  }


  async updateTimeslots(resourceToAdd: IResourceToAdd) {
    if (resourceToAdd.resourceId) {
      resourceToAdd.resourceInfo.startTimes = [];
      resourceToAdd.resourceInfo.endTimes = [];
      resourceToAdd.resourceInfo.timeslots = Object.assign({}, this.resourceTimeslots[resourceToAdd.resourceId]);


      let enoughSlots = (slots: number) => {
        return this.quickResourceBookingState.selectedPersons <= slots || this.quickResourceBookingState.allowMorePersonsThanResourceMax;
      }

      console.log(`Persons: ${this.quickResourceBookingState.selectedPersons}`);

      for (let time of Object.keys(resourceToAdd.resourceInfo.timeslots).sort((a, b) => a.localeCompare(b))) {
        if (enoughSlots(resourceToAdd.resourceInfo.timeslots[time].slots)) {
          resourceToAdd.resourceInfo.startTimes.push({
            time: time,
            slots: resourceToAdd.resourceInfo.timeslots[time].slots
          });
          resourceToAdd.resourceInfo.endTimes.push({
            time: addDurationToTime(time, resourceToAdd.resourceInfo.resource.timeslotInterval),
            slots: resourceToAdd.resourceInfo.timeslots[time].slots
          });
        }
        else {
          console.log(`Not enough slots for ${time}`);
        }
      };

      // Remove start times after end time
      if (resourceToAdd.endTime) {
        let startTimesBeforeEndTime = [];
        for (let timeslot of resourceToAdd.resourceInfo.startTimes) {
          if (timeDifferenceInMinutes(timeslot.time, resourceToAdd.endTime) > 0)
            startTimesBeforeEndTime.push(timeslot);
        }
        resourceToAdd.resourceInfo.startTimes = startTimesBeforeEndTime;
      }

      // Remove end times before start time
      if (resourceToAdd.startTime) {
        let endTimesAfterStartTime = [];
        for (let timeslot of resourceToAdd.resourceInfo.endTimes) {
          if (timeDifferenceInMinutes(timeslot.time, resourceToAdd.startTime) < 0)
            endTimesAfterStartTime.push(timeslot);
        }
        resourceToAdd.resourceInfo.endTimes = endTimesAfterStartTime;
      }

      // Remove non-continous start timeslots
      if (resourceToAdd.endTime) {
        let continousStartTimes = [];
        let currentTime = resourceToAdd.endTime;
        currentTime = subtractDurationFromTime(currentTime, resourceToAdd.resourceInfo.resource.timeslotInterval);
        let timeslot;
        while (timeslot = resourceToAdd.resourceInfo.startTimes.find(time => time.time == currentTime && enoughSlots(time.slots))) {
          continousStartTimes.push(timeslot);
          currentTime = subtractDurationFromTime(currentTime, resourceToAdd.resourceInfo.resource.timeslotInterval);
        }
        continousStartTimes = continousStartTimes.reverse()
        resourceToAdd.resourceInfo.startTimes = continousStartTimes;
      }

      // Remove non-continous end timeslots
      if (resourceToAdd.startTime) {
        let continousEndTimes = [];
        let currentTime = resourceToAdd.startTime;
        currentTime = addDurationToTime(currentTime, resourceToAdd.resourceInfo.resource.timeslotInterval);
        let timeslot;
        while (timeslot = resourceToAdd.resourceInfo.endTimes.find(time => time.time == currentTime && enoughSlots(time.slots))) {
          continousEndTimes.push(timeslot);
          try {
            currentTime = addDurationToTime(currentTime, resourceToAdd.resourceInfo.resource.timeslotInterval);
          }
          catch {
            // If time ends up on new day
            break;
          }
        }
        resourceToAdd.resourceInfo.endTimes = continousEndTimes;
      }



      console.log(`AllowMorePersonsThanResourceMax: ${this.quickResourceBookingState.allowMorePersonsThanResourceMax}`)
      console.log(resourceToAdd.resourceInfo.startTimes);
      console.log(resourceToAdd.startTime);
      // Unset the start time if it is not available
      if (resourceToAdd.startTime
        && !resourceToAdd.resourceInfo.startTimes.some(time =>
          time.time == resourceToAdd.startTime
          && enoughSlots(time.slots)
        )
      ) {
        resourceToAdd.startTime = null;
      }

      // Unset the end time if it is not available
      if (resourceToAdd.endTime &&
        !resourceToAdd.resourceInfo.endTimes.some(time =>
          time.time == resourceToAdd.endTime
          && enoughSlots(time.slots))) {
        resourceToAdd.endTime = null;
      }
    }
    else {
      resourceToAdd.startTime = null;
      resourceToAdd.endTime = null;
    }
  }

  updateAllTimeslots() {
    for (let resourceToAdd of this.resourcesToAdd) {
      this.updateTimeslots(resourceToAdd);
    }
  }


  getAvailableStartTimes(resourceToAdd: IResourceToAdd) {
    if (resourceToAdd.resourceInfo.timeslots) {
      return Object.keys(resourceToAdd.resourceInfo.timeslots).map(time => {
        return { time: time, slots: resourceToAdd.resourceInfo.timeslots[time].slots };
      });
    }
    return [];
  }

  getAvailableEndTimes(resourceToAdd: IResourceToAdd) {
    if (resourceToAdd.resourceInfo.timeslots) {
      return Object.keys(resourceToAdd.resourceInfo.timeslots).map(time => {
        return { time: time, slots: resourceToAdd.resourceInfo.timeslots[time].slots };
      });
    }
    return [];
  }

  startTimeSelected(resourceToAdd: IResourceToAdd) {
    this.updateTimeslots(resourceToAdd);
  }

  endTimeSelected(resourceToAdd: IResourceToAdd) {
    this.updateTimeslots(resourceToAdd);
  }

  removeEndTime(resourceToAdd: IResourceToAdd) {
    delete resourceToAdd.endTime;
    this.updateTimeslots(resourceToAdd);
  }

  personsChanged(e) {
    console.log(e);
    this.quickResourceBookingState.selectedPersons = e.value;
    this.updateAllTimeslots();
    /*
    for (let resourceToAdd of this.resourcesToAdd) {
      this.updateTimeslots(resourceToAdd);
    }
    */
  }

  async dayChanged() {
    console.log('Day changed');
    if (this.selectedDate) {
      this.quickResourceBookingState.selectedDay = moment(this.selectedDate).format('YYYY-MM-DD');
      for (let resourceToAdd of this.resourcesToAdd) {
        this.updateTimeslots(resourceToAdd);
      }
    }

  }

  async refetchTimeslots(resourceToAdd: IResourceToAdd) {
    if (resourceToAdd.resourceId) {
      this.resourceTimeslots[resourceToAdd.resourceId] = (await this.api.client().get<any>(`/resources/${resourceToAdd.resourceId}/available-slots/${this.quickResourceBookingState.selectedDay}`)).dates[this.quickResourceBookingState.selectedDay].timeslots;
      this.updateTimeslots(resourceToAdd);
    }
  }

  async resourceSelected(resourceToAdd: IResourceToAdd) {
    resourceToAdd.resourceInfo.resource = this.availableResources.find(r => r.id == resourceToAdd.resourceId);
    await this.refetchTimeslots(resourceToAdd);
  }

  async createResourceBooking() {
    if (!this.creatingBooking) {
      this.creatingBooking = true;

      this.quickResourceBookingState.selectedResources = [];
      for (let resourceToAdd of this.resourcesToAdd) {
        let selectedResource = {
          name: resourceToAdd.resourceInfo.resource.name,
          resourceId: resourceToAdd.resourceId,
          uniqueResourceId: resourceToAdd.uniqueResourceId,
          lockTimeslots: resourceToAdd.lockTimeslots,
          timeslots: []
        };
        let time = resourceToAdd.startTime;
        while (time != resourceToAdd.endTime) {
          selectedResource.timeslots.push(time);
          time = addDurationToTime(time, resourceToAdd.resourceInfo.resource.timeslotInterval);
        }
        this.quickResourceBookingState.selectedResources.push(selectedResource);
      }

      let result = await this.api.client().post<any>(`/quick-resource-booking`, this.quickResourceBookingState);
      if (result.succeeded) {
        this.creatingBooking = false;
        this.resourceBookingCreated.emit(result.booking);
      }
    }
  }

  async ngAfterViewInit() {

  }



  async ngOnInit() {
    this.quickResourceBookingState.selectedDay = moment(this.selectedDate).format('YYYY-MM-DD');
    this.availableResources = await this.api.client().get<any>(`/resources`);

    //filtering out disabled resources for resource list
    this.availableResources = this.availableResources.filter(r => !r.disabled);

    if (this.resourceBookingToRebook) {
      await this.populateFromResourceBookingToRebook();
    } else {
      this.addResource();
    }
  }

  async populateFromResourceBookingToRebook() {
    this.quickResourceBookingState.rebookedResourceBookingNumber = this.resourceBookingToRebook.bookingNumber;
    this.quickResourceBookingState.rebookedResourceBookingId = this.resourceBookingToRebook.id;
    this.quickResourceBookingState.skipNotification = true;
    this.quickResourceBookingState.customerInfo = Object.assign({}, this.resourceBookingToRebook.customer) as any;
    this.quickResourceBookingState.staffComment = this.resourceBookingToRebook.staffComment;
    this.quickResourceBookingState.selectedDay = this.resourceBookingToRebook.localDay;
    this.quickResourceBookingState.isPaid = this.resourceBookingToRebook.isPaid;
    this.quickResourceBookingState.newsletterAccepted = this.resourceBookingToRebook.newsletterAccepted;
    this.quickResourceBookingState.selectedPersons = this.resourceBookingToRebook.persons;
    this.quickResourceBookingState.price = {
      vat: this.resourceBookingToRebook.price.vat,
      vatPercentage: this.resourceBookingToRebook.priceVatPercentage,
      withVat: this.resourceBookingToRebook.price.withVat,
      withoutVat: this.resourceBookingToRebook.price.withoutVat
    };
    this.selectedDate = moment(this.resourceBookingToRebook.startDate).toDate();

    if (this.resourceBookingToRebook.metadata && this.resourceBookingToRebook.metadata.allowMorePersonsThanResourceMax)
      this.quickResourceBookingState.allowMorePersonsThanResourceMax = true;

    for (let resource of this.resourceBookingToRebook.resources) {
      let resourceToAdd: IResourceToAdd = {
        resourceId: resource.resourceId,
        uniqueResourceId: this.utility.uuid(),
        startTime: resource.startTime,
        endTime: resource.endTime,
        lockTimeslots: resource.lockTimeslots,
        resourceInfo: {
          resource: this.availableResources.find(r => r.id == resource.resourceId),
          timeslots: {},
          startTimes: [],
          endTimes: []
        }
      };

      await this.refetchTimeslots(resourceToAdd);
      this.updateTimeslots(resourceToAdd);
      this.resourcesToAdd.push(resourceToAdd);
    }
  }


  updateStaffCommentSize() {
    if (this.textArea) {
      this.textArea.nativeElement.style.height = 'auto';
      this.textArea.nativeElement.style.height = (this.textArea.nativeElement.scrollHeight) + 'px';

      if(this.quickResourceBookingState.staffComment == "") {
        this.textArea.nativeElement.style.height = "24px";
      }
    }
  }
}
