import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { IClientSettings } from "../interfaces/client-settings";
import { Currency } from "../../../../../../common/common-types/currency";
import { Observable } from "rxjs";
import { FunbutlerClientAPI, FunbutlerEventEmitter, ITrackingEvent } from "../interfaces/funbutler-client-api";



@Injectable()
export class Globals {
    clientId?: string;
    currency?: Currency;
    clientSettings?: IClientSettings;
    baseUrl?: string = environment.baseUrl;
    customTextCache: { [id: string]: any } = {};
    locale: string;
    embedMode: boolean;
    standalone: boolean;
    useDarkMode?: boolean;
    funbutlerAPI: FunbutlerClientAPI;

    constructor() {
        this.embedMode = (window as any).funbutlerEmbedMode;
        this.standalone = (window as any).funbutlerStandalone;
        this.funbutlerAPI = (window as any).funbutlerAPI = {
            tracking: {
                event: new FunbutlerEventEmitter()
            }
        };

        window.dispatchEvent(new CustomEvent("funbutlerApiAvailable", {
            detail: this.funbutlerAPI
        }));

    }

    

    determineCartId(clientId: string) {
        // Format: #cart(CART_ID)
        if (window.location.hash.includes('cartId(')) {
            let cartId = window.location.hash.split('cartId(')[1].split(')')[0];
            localStorage.setItem(`${clientId}_cartId`, cartId);
            window.location.href = window.location.href.split('#')[0];
        }
    }
}
