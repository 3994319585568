import { Component, OnInit, EventEmitter, ViewChild, Output, Input, ElementRef } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { IPackage } from 'projects/client-app/src/app/interfaces/package';
import { IAddOn } from 'projects/client-app/src/app/interfaces/add-on';
import { IQuickBookingFormState, ISelectedAddOn } from 'projects/client-app/src/app/interfaces/quick-booking-form-state';
import { Api } from 'projects/client-app/src/app/services/api';
import { IBooking } from 'src/app/interfaces/booking';
import { Utility } from 'projects/client-app/src/app/services/utility';
import { Globals } from 'projects/client-app/src/app/services/globals';
import { FormControl } from '@angular/forms';
import { i } from '@fullcalendar/resource/internal-common';


@Component({
    selector: 'quick-booking',
    templateUrl: './quick-booking.component.html',
    styleUrls: ['./quick-booking.component.scss']
})

export class QuickBookingComponent implements OnInit {
    @Input()
    selectedDate: Date = moment().toDate();

    availablePackages: IPackage[];

    selectedPackageAutoCodes: string[] = [];

    availableTimeslots: any;

    selectedCustomerGroups: { [customerGroupId: string]: { name: string, quantity: number, customerGroupId: string } };
    selectedCustomerGroupId: string;
    availableCustomerGroups: any[];
    filteredCustomerGroups: any[];

    availableExtraActivitiesTimeslots: any = {}

    codeToApply = "";
    codeInvalid = new FormControl('');
    validatingCode: boolean = false;
    codeHandler: any;


    availableAddOns: IAddOn[];
    availableAddOnsOnPackage: ISelectedAddOn[] = [];

    quickBookingState: IQuickBookingFormState = {
        selectedPersons: 0,
        selectedCustomerGroups: Object.values({}),
        isPaid: false,
        hasArrived: false,
        notChangeable: false,
        newsletterAccepted: false,
        customerInfo: {
            firstName: "",
            lastName: "",
            email: "",
            phone: ""
        },
        codesToApply: [],
        birthdayInfo: { persons: [] },
        selectedDay: moment().format('YYYY-MM-DD'),
        selectedTime: "",
        selectedExtraActivities: [],
        selectedAddOns:[],
    }

    creatingBooking = false;

    @ViewChild('packageSelect', { static: false }) packageSelect: MatSelect;

    @Output()
    bookingCreated = new EventEmitter<IBooking>();

    @ViewChild('staffCommentTextarea', { static: false })
    textArea: ElementRef<HTMLTextAreaElement>;


    constructor(private api: Api, translate: TranslateService, public utility: Utility, public globals: Globals) {
        this.quickBookingState.customerInfo.firstName = translate.instant('DROP_IN');
    }

    validate() {
        return !this.creatingBooking
            && this.availableTimeslots
            && this.availableTimeslots[this.quickBookingState.selectedTime]
            && this.quickBookingState.selectedPackage
            && this.quickBookingState.selectedDay
            && this.quickBookingState.selectedTime
            && this.quickBookingState.selectedPersons >= this.quickBookingState.selectedPackage.minPeoplePerBooking
            && this.quickBookingState.selectedPersons <= this.quickBookingState.selectedPackage.maxPeoplePerBooking
            && this.quickBookingState.selectedPersons <= this.availableTimeslots[this.quickBookingState.selectedTime].availableSlots
            && this.quickBookingState.customerInfo.firstName != '';
    }

    showCustomerInfo(){
        return this.quickBookingState.selectedPackage
            && this.quickBookingState.selectedDay
            && this.quickBookingState.selectedTime
            && (this.quickBookingState.selectedPersons > 0
            && this.quickBookingState.selectedPersons >= this.quickBookingState.selectedPackage.minPeoplePerBooking
            && this.quickBookingState.selectedPersons <= this.quickBookingState.selectedPackage.maxPeoplePerBooking);
    }


    addOnQuantityChanged(addOn, data) {
        addOn.quantity = data.value;
        console.log(this.quickBookingState)


        //if the quickBookingState do not have the addOn already add it to the array
        if(!this.quickBookingState.selectedAddOns.find((a) => a.addOnId == addOn.addOnId)) {
            this.quickBookingState.selectedAddOns.push({
                name: addOn.name,
                quantity: addOn.quantity,
                addOnId: addOn.addOnId
            });
            return;
        }

        //if addOn has a quantity of 0 or less, then remove it from the quickBookingState§
        if(addOn.quantity <= 0) {
            this.quickBookingState.selectedAddOns = this.quickBookingState.selectedAddOns.filter((a) => a.addOnId != addOn.addOnId);
            return;
        }

        let index = this.quickBookingState.selectedAddOns.findIndex((a) => a.addOnId == addOn.addOnId);
        if(index) {
            this.quickBookingState.selectedAddOns[index].quantity = data.value;
        }

    }

    async fetchTimeslots() {
        this.availableTimeslots = null;
        this.quickBookingState.selectedTime = null;
        let endDate = this.quickBookingState.selectedPackage.allowMidnightOverflow
            ? moment(this.quickBookingState.selectedDay).add(2, 'days').format('YYYY-MM-DD')
            : moment(this.quickBookingState.selectedDay).add(1, 'day').format('YYYY-MM-DD');

        let result = await this.api.publicClient()
            .get<any>(`/packages/${this.quickBookingState.selectedPackage.id}/available-timeslots?startDate=${encodeURIComponent(this.quickBookingState.selectedDay)}&endDate=${encodeURIComponent(endDate)}`);

        if (result.timeslots && result.timeslots.dates && result.timeslots.dates[this.quickBookingState.selectedDay]) {
            this.availableTimeslots = result.timeslots.dates[this.quickBookingState.selectedDay];

            let times = Object.keys(this.availableTimeslots);
            for (let time of times) {
                if (!this.availableTimeslots[time].availableSlots)
                    delete this.availableTimeslots[time];
            }

            // Filter old timeslots
            if (this.quickBookingState.selectedDay == moment().format('YYYY-MM-DD')) {
                for (let time in this.availableTimeslots) {
                    if (moment(`${this.quickBookingState.selectedDay} ${time}`).add(1, 'hour').isBefore(moment()))
                        delete this.availableTimeslots[time];
                }
            }

            // Select the next upcoming time
            for (let time in this.availableTimeslots) {
                if (moment(`${this.quickBookingState.selectedDay} ${time}`).isAfter(moment())) {
                    this.quickBookingState.selectedTime = time;
                    this.timeSelected();
                    break;
                }
            }


            if (this.quickBookingState.selectedPackage.extraActivities && this.quickBookingState.selectedPackage.extraActivities.length)
                this.availableExtraActivitiesTimeslots = (await this.api.publicClient()
                    .get<any>(`/packages/${this.quickBookingState.selectedPackage.id}/extra-activities/timeslots?day=${encodeURIComponent(this.quickBookingState.selectedDay)}`)).activities;
        }
    }

    async timeSelected() {
        console.log('Time selected');
        console.log(this.availableTimeslots[this.quickBookingState.selectedTime]);
        if (this.availableTimeslots[this.quickBookingState.selectedTime]
            && this.availableTimeslots[this.quickBookingState.selectedTime].hasBookings
            && this.availableTimeslots[this.quickBookingState.selectedTime].customerGroupIds) {
            this.selectedCustomerGroupId = Object.keys(this.availableTimeslots[this.quickBookingState.selectedTime].customerGroupIds)[0];
            console.log(this.selectedCustomerGroupId);

            this.codeToApply = "";
            this.quickBookingState.codesToApply = [];
        }
    }

    isCustomerGroupHidden(customerGroupId) {
        if (this.availableTimeslots[this.quickBookingState.selectedTime]
            && this.availableTimeslots[this.quickBookingState.selectedTime].hasBookings
            && this.availableTimeslots[this.quickBookingState.selectedTime].customerGroupIds) {
            if (this.availableTimeslots[this.quickBookingState.selectedTime].customerGroupIds[customerGroupId]) {
                return false;
            }
            else
                return true;
        }

        if (this.quickBookingState.selectedPackage.lockToCustomerGroup) {
            if (this.selectedCustomerGroupId && this.selectedCustomerGroupId != customerGroupId && this.quickBookingState.selectedPersons > 0)
                return true;
        }
        return false;
    }

    async packageSelected() {
        this.selectedCustomerGroups = {};
        this.selectedCustomerGroupId = null;
        this.filteredCustomerGroups = this.availableCustomerGroups;


        //sort out customerGroups that have no price on the package so the quick-booking is not
        //clutterd with a bunch of customer group
        let groups = [];
        for(let priceSettings of this.quickBookingState.selectedPackage.priceSettings){
          for(let key of Object.keys(priceSettings.customerGroupPrices) ){
            if(priceSettings.customerGroupPrices[key].enabled) {
                groups.push(key);
            }
          }
        }

        this.filteredCustomerGroups = this.availableCustomerGroups.filter((group) => {
            return groups.includes(group.id);
        });

        for (let customerGroup of this.filteredCustomerGroups) {
            this.selectedCustomerGroups[customerGroup.id] = {
                name: customerGroup.name,
                quantity: 0,
                customerGroupId: customerGroup.id
            }
        }

        this.fetchTimeslots();

        this.availableAddOnsOnPackage = [];
        for (let addOnId in this.quickBookingState.selectedPackage.enabledAddOnIds) {
            let addOn = this.availableAddOns.find(a => a.id == addOnId);
            if(!addOn.disabled){
                this.availableAddOnsOnPackage.push({
                    addOnId: addOn.id,
                    name: addOn.name,
                    quantity: 0
                });
            }
        }

        this.selectedPackageAutoCodes = [];
        for(let code of this.quickBookingState.selectedPackage.autoApplyPromoCodeIds) {
            let packageCode: any = await this.api.client().get<any[]>(`/manage/promo-codes/${code}`)
            this.selectedPackageAutoCodes.push(packageCode.code);
        }

        this.codeToApply = "";
        this.quickBookingState.codesToApply = [];

    }

    async dayChanged() {
        console.log('Day changed');
        if (this.quickBookingState.selectedDay) {
            this.quickBookingState.selectedDay = moment(this.selectedDate).format('YYYY-MM-DD');
            this.fetchTimeslots();
            this.codeToApply = "";
            this.quickBookingState.codesToApply = [];
        }

    }

    async createBooking() {
        //try to add code if there is one
        if(this.codeToApply != "") {
            this.addCodeToList();
        }

        if (!this.creatingBooking) {
            this.creatingBooking = true;
            let result = await this.api.client().post<any>(`/quick-booking`, this.quickBookingState);
            if (result.succeeded) {
                this.creatingBooking = false;
                this.bookingCreated.emit(result.booking);
            }
        }
    }

    customerGroupPersonsChanged(customerGroupId, data) {
        let oldQuantity = this.selectedCustomerGroups[customerGroupId].quantity;
        let newValue = 0;
        this.selectedCustomerGroups[customerGroupId].quantity = data.value;
        console.log(this.selectedCustomerGroups);
        for (let customerGroupId in this.selectedCustomerGroups) {
            newValue += this.selectedCustomerGroups[customerGroupId].quantity;
        }
        if (newValue > this.quickBookingState.selectedPackage.maxPeoplePerBooking) {
            this.selectedCustomerGroups[customerGroupId].quantity = oldQuantity;
            data.revert();
        }
        else {
            this.quickBookingState.selectedPersons = newValue;
        }

        if (this.quickBookingState.selectedPackage.lockToCustomerGroup) {
            this.selectedCustomerGroupId = customerGroupId;
        }

        this.quickBookingState.selectedCustomerGroups = Object.values(this.selectedCustomerGroups)
    }

    personsChanged(data) {
        if (data.value > this.quickBookingState.selectedPackage.maxPeoplePerBooking)
            data.revert();
        else
            this.quickBookingState.selectedPersons = data.value;

        this.codeToApply = "";
        this.quickBookingState.codesToApply = [];

    }

    async ngOnInit() {
        this.quickBookingState.selectedDay = moment(this.selectedDate).format('YYYY-MM-DD');
        this.availablePackages = await this.api.client().get<IPackage[]>(`/quick-booking/packages`);
        this.availableAddOns = await this.api.publicClient().get<IAddOn[]>(`/add-ons`);
        this.availableCustomerGroups = (await this.api.publicClient().get<any[]>(`/customer-groups`)).filter(cg => !cg.disabled);
        this.packageSelect.focus();
    }



    applyCodeActivated(): boolean {
        return true
    }


    async codeKeyUp(): Promise<void> {
      if (this.codeHandler)
        clearInterval(this.codeHandler);

      this.validatingCode = false;

      //check the code if the user has not inputed text after 500ms
      //unless its already checking a code
      this.codeHandler = setInterval(() => {
        if(!this.validatingCode){
          clearInterval(this.codeHandler);
          this.validatePromoCode();
        }
      }, 500);
    }


    async validatePromoCode(): Promise<void>{
      this.validatingCode = true;
      let query = `code=${this.codeToApply}&visitDate=${this.quickBookingState.selectedDay}&packageId=${this.quickBookingState.selectedPackage.id}&persons=${this.quickBookingState.selectedPersons}`;
      let result = await this.api.client().get<any>(`/quick-booking/gift-or-promo-code?${query}`);
      this.validatingCode = false;

      //check if the code is invalid or already exsists
      let amount = this.quickBookingState.codesToApply.includes(this.codeToApply);
      if(result.invalid || amount || this.selectedPackageAutoCodes.includes(this.codeToApply)) {
        if(this.codeToApply != "") {
          this.codeInvalid.setErrors({invalid : true, reason : result.reason});
        }
        return;
      }

      //remove errors
      this.codeInvalid.setErrors(null);

      //add code to list
      this.addCodeToList();
    }

    addCodeToList(): void {
      //just in case because you never know
      if(this.codeToApply == "" && this.codeInvalid.hasError("invalid"))
        return;

      this.quickBookingState.codesToApply.push(this.codeToApply)
      this.codeToApply = "";
    }

    removeCode(code: string): void {
      let index = this.quickBookingState.codesToApply.findIndex((c) => c == code);

      if(index)
          this.quickBookingState.codesToApply.splice(index, 1);
    }



    /* BIRTHDAY CHILD LOGIC */

    addBirthDayInfo(): void {
       //check if persons is an array otherwise make it to an array
      if(this.quickBookingState.birthdayInfo.persons.length == 0) {
        this.quickBookingState.birthdayInfo.persons = [];
      }

      this.quickBookingState.birthdayInfo.persons.push({
          name: "",
          birthDate: new Date()
      });
    }

    removeBirthDayInfo(index: number): void {
      if(this.quickBookingState.birthdayInfo.persons.length <= 0 || !this.quickBookingState.birthdayInfo.persons) {
        return;
      }

      this.quickBookingState.birthdayInfo.persons.splice(index, 1);
    }

    birthDayInfoEnabled(): boolean {
      if(this.globals.clientSettings && this.globals.clientSettings.enableQuickBookingBirthdayChild && this.quickBookingState.selectedPackage.type == 'BIRTHDAY') {
        return true;
      }

      this.quickBookingState.birthdayInfo.persons = [];
      return false;
    }

    updateStaffCommentSize() {
      if (this.textArea) {
        this.textArea.nativeElement.style.height = 'auto';
        this.textArea.nativeElement.style.height = (this.textArea.nativeElement.scrollHeight) + 'px';

        if(this.quickBookingState.staffComment == "") {
          this.textArea.nativeElement.style.height = "24px";
        }
      }
    }
}
