import { Component, OnInit, ViewChild } from '@angular/core';
import { Api } from '../../services/api';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../../services/globals';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Currency } from '../../services/currency';
import { Utility } from '../../services/utility';
import { MatTable } from '@angular/material/table';
import { CurrentUser } from '../../services/user';

@Component({
    selector: 'gift-card-administration',
    templateUrl: './gift-card-administration.component.html',
    styleUrls: ['./gift-card-administration.component.scss']
})

export class GiftCardAdministrationComponent implements OnInit {
    giftCardId: any;
    giftCard: any | null = null;
    giftCardExpired: boolean = false;

    giftCardOrder: any = null;
    giftCardsInTheOrder: any[] | null = null

    displayedColumns = ['code', 'amount', 'experation'];

    paramsSubscription: any;

    @ViewChild('giftCardsTable', { static: false }) giftCardsTable: MatTable<any>;

    constructor(private api: Api, private router: Router, private route: ActivatedRoute,
    private globals: Globals, public translate: TranslateService, private utility: Utility,
    public currency: Currency, public currentUser: CurrentUser) {

    }

    async ngOnInit() {
        this.paramsSubscription = this.route.params.subscribe(async (params) => {
            if (params['id']) {
                this.giftCardId = params['id'];

                this.giftCard = await this.api.client().get<any>(`/manage/gift-cards/${this.giftCardId}`);

                //load gift card order if it exists for this gift card
                if (this.giftCard.giftCardOrderId) {
                    let tempArray = [];
                    this.giftCardOrder = await this.api.client().get<any>(`/gift-card-orders/${this.giftCard.giftCardOrderId}`);
                    console.log(this.giftCardOrder);

                    //go through all gift cards in the order and add them to the tempArray
                    this.giftCardOrder.giftCardIds.forEach(async (giftCardId: string) => {

                        //but only if they are not the same as the current gift card
                        if (giftCardId != this.giftCardId) {
                            let otherGiftCards = await this.api.client().get<any>(`/manage/gift-cards/${giftCardId}`);
                            otherGiftCards.experationDateFormated = this.getExpirationDateFormated(otherGiftCards.expirationDate);

                            tempArray.push(otherGiftCards);
                        }

                    });

                    this.giftCardsInTheOrder = tempArray;
                }

                console.log(this.giftCard);

                //calculate if the gift card is expired or not
                this.giftCardExpired = moment(this.giftCard.expirationDate).isBefore(moment()) ? true : false;

                //this is a workaround for the table not rendering the data
                //becuase sometimes it is not rendered
                let timer = setInterval(() => {
                    if (this.giftCardsTable) {
                        this.giftCardsTable.renderRows();
                        clearInterval(timer);
                    }
                }, 1000);
            }

        });


    }

    openGiftCard() {
        window.open(`${this.globals.baseUrl}/api/public/clients/${this.globals.clientId}/gift-cards/${this.giftCardId}/view`, "_blank");
    }

    async save() {
        this.giftCard = await this.api.client().patch<any>(`/manage/gift-cards/${this.giftCardId}`, this.giftCard);
    }

    getExpirationDateFormated(date) {
        return moment(date).format("YYYY-MM-DD")
    }

    async OnDestroy() {
        if (this.paramsSubscription) {
            this.paramsSubscription.unsubscribe();
        }
    }

    giftCardValueDisabled(){
      if(this.currentUser.user.role == 'superadmin') {
        return false;
      }

      if(this.currentUser.user.role != 'manager'){
        return true;
      }

      if(this.globals.clientSettings && this.globals.clientSettings.enableCashRegisterIntegration){
        return true;
      }

      return false;
    }

}
