import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../../../services/globals';
import { TranslateService } from '@ngx-translate/core';
import { IPrice } from '../../../interfaces/price';

interface IVatList {
    name: string,
    value: number
}

@Component({
  selector: 'price-input-new',
  templateUrl: './price-input-new.component.html',
  styleUrls: ['./price-input-new.component.scss']
})

export class PriceInputNewComponent implements OnInit, OnDestroy {
  @Input()
  price: IPrice;

  listOfVat: IVatList[] = [
    { name: "0%", value: 0 },
    { name: "5%", value: 5 },
    { name: "6%", value: 6 },
    { name: "7%", value: 7 },
    { name: "7.7%", value: 7.7 },
    { name: "8%", value: 8 },
    { name: "8.1%", value: 8.1 },
    { name: "9%", value: 9 },
    { name: "10%", value: 10 },
    { name: "12%", value: 12 },
    { name: "13.5%", value: 13.5 },
    { name: "14%", value: 14 },
    { name: "15%", value: 15 },
    { name: "18%", value: 18 },
    { name: "19%", value: 19 },
    { name: "20%", value: 20 },
    { name: "21%", value: 21 },
    { name: "23%", value: 23 },
    { name: "24%", value: 24 },
    { name: "25%", value: 25 },
    { name: "25.5%", value: 25.5 }
  ];

  constructor(private router: Router, private route: ActivatedRoute, private globals: Globals, private translate: TranslateService) {

  }



  async calculateVat() {
    console.log(this.price.withVat);
    if (['undefined', 'null'].includes(typeof (this.price.withVat)))
      this.price.withVat = 0;
    console.log(this.price.withVat);

    let factor = 1 / (1 + (this.price.vatPercentage / 100));
    this.price.withoutVat = parseFloat((this.price.withVat * factor).toFixed(6));
    this.price.vat = this.price.withVat - this.price.withoutVat;
  }

  async ngOnInit() {

  }

  async ngOnDestroy() {

  }
}
