import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef, ComponentFactoryResolver, Injector, ComponentRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import listPlugin from '@fullcalendar/list';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import { FullCalendarComponent } from '@fullcalendar/angular';
import allLocales from '@fullcalendar/core/locales-all';
import * as  moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { IResource } from '../../../interfaces/resource';
import { Api } from '../../../services/api';
import { Globals } from '../../../services/globals';
import { Utility } from '../../../services/utility';
import { Currency } from '../../../services/currency';
import { IBooking } from 'src/app/interfaces/booking';
import { IResourceBooking, IResourceBookingResource } from 'src/app/interfaces/resource-booking';
import { IEvent, IEventDate } from '../../../../../../../../../../common/common-interfaces/event';
import { IPackage } from '../../../interfaces/package';
import { CurrentUser } from '../../../services/user';
import { DomSanitizer } from '@angular/platform-browser';
import { orderBy } from '../../../helpers/utility';


@Component({
  selector: 'check-in-calendar-view-new',
  templateUrl: './check-in-calendar-view-new.component.html',
  styleUrls: ['./check-in-calendar-view-new.component.scss']
})

export class CheckInCalendarViewNewComponent implements OnInit {
  @ViewChild('fullcalendar', { static: false }) fullcalendar: FullCalendarComponent;
  calendarOptions: any = {};
  resourcePersons: { [resourceId: string]: number } = {};
  resources: { [resourceId: string]: IResource } = {};
  packages: { [resourceId: string]: IPackage } = {};
  bookingAutoFetchSuspended = false;
  selectedDate: Date = new Date();

  allResourcesExpanded = false;
  @Input()
  set date(date: Date) {
    this.selectedDate = date;
    console.log(`Date set: ${date}`);
    this.updateDate();
  }

  get date(): Date { return this.selectedDate; }

  @Output()
  bookingClicked = new EventEmitter();

  constructor(private api: Api, private globals: Globals, private utility: Utility, private translate: TranslateService, private dialog: MatDialog, private componentFactoryResolver: ComponentFactoryResolver, private injector: Injector, private currency: Currency, private currentUser: CurrentUser, private sanitizer: DomSanitizer) {
    this.calendarOptions = {
      header: {
        right: '',
        left: '',
        center: 'prev,title,next,today'
      },
      defaultView: 'resourceTimeline',
      locales: allLocales,
      resources: this.getResources(this),
      events: this.getEvents(this),
      loading: this.onLoading(this),
      initialView: 'resourceTimeline',
      /*    resources: this.getResources(this),
          events: this.getEvents(this),
          loading: this.onLoading(this),
          */
      initialDate: this.selectedDate,
      nowIndicator: true,
      resourceOrder: 'title',
      locale: globals.language,
      slotDuration: '00:05:00',
      slotLabelInterval: '00:30:00',
      schedulerLicenseKey: '0871770502-fcs-1698133688',
      plugins: [resourceTimelinePlugin, resourceTimeGridPlugin]
    };
  }


  updateDate() {
    if (this.fullcalendar) {
      this.fullcalendar.getApi().gotoDate(this.selectedDate);
      let time = moment(this.selectedDate).format('HH') + ':00:00';
      this.fullcalendar.getApi().scrollToTime(time);
    }
  }

  changeView(view) {
    this.globals.userSettings.checkInView = view;
    this.globals.saveUserSettings();
    switch (view) {
      case 'CALENDAR':
        this.calendarOptions.defaultView = 'resourceTimeline';
        setTimeout(() => {
          this.setupCalendar();
        });
        break;
    }
  }

  fetchBookings() {
    this.fullcalendar.getApi().refetchEvents();
  }

  reRender(refetch?: boolean) {
    if (refetch)
      this.fetchBookings();
    //     else
    //     (this.fullcalendar.getApi() as any).rerenderEvents();

  }


  birthdayPersons(booking: any) {
    if (booking && booking.birthdayInfo && booking.birthdayInfo.persons)
      return booking.birthdayInfo.persons.map(p => p.name).join(', ')
    return '';
  }

  async toggleResourceExpanded(resourceId: string) {
    console.log(`Toggling resource expanded ${resourceId}`);
    this.globals.userSettings.calendarPreferences.expandedResources[resourceId] = !this.globals.userSettings.calendarPreferences.expandedResources[resourceId];
    this.globals.saveUserSettings();
    this.fullcalendar.getApi().render();
    setTimeout(() => {
      this.setCalendarHeight();
      this.setResourceAreaWidth(this);
      this.fullcalendar.getApi().refetchEvents();
    }, 500);
  }

  async toggleAllResourceExpanded() {
    console.log(`Toggling all resource expanded`);
    this.allResourcesExpanded = !this.allResourcesExpanded;
    for (let resourceId in this.resources) {
      this.globals.userSettings.calendarPreferences.expandedResources[resourceId] = this.allResourcesExpanded;
    }

    this.globals.saveUserSettings();
    this.fullcalendar.getApi().render();
    setTimeout(() => {
      this.setCalendarHeight();
      this.setResourceAreaWidth(this);
      this.fullcalendar.getApi().refetchEvents();
    }, 500);
  }

  setCalendarHeight() {
    console.log('Setting calendar height');
    console.log(this.fullcalendar);
    let headerHeight = document.getElementById('header').offsetHeight + 50;
    this.fullcalendar.getApi().setOption('height', window.innerHeight - headerHeight);
  }

  setResourceAreaWidth(_this: CheckInCalendarViewNewComponent) {
    console.log('Setting resource width');
    let longestNameLength = 0;
    for (let resource of (_this.fullcalendar.getApi() as any).getResources()) {
      longestNameLength = Math.max(longestNameLength, resource.extendedProps.name.length);
    }
    _this.fullcalendar.getApi().setOption('resourceAreaWidth', `${30 + (longestNameLength * 11)}px`);
  }

  getResources(_this: CheckInCalendarViewNewComponent) {
    return async () => {
      let resources = (await _this.api.client().get<IResource[]>('/resources')).filter(r => {
        return !r.disabled
          && (!this.globals.userSettings
            || !this.globals.userSettings.hiddenCalendarResources
            || !this.globals.userSettings.hiddenCalendarResources.includes(r.id)
          );
      });
      this.resources = {};
      for (let resource of resources) {
        this.resources[resource.id] = resource;
      }

      console.log('Resources:');
      console.log(resources);
      resources.sort(orderBy('name').asc);
      console.log('Resources sorted:');
      console.log(resources);

      if (this.globals.userSettings.showEventsWithoutResourcesInCalendar) {
        resources.push({ name: this.translate.instant('EVENTS'), id: 'EVENTS_WITHOUT_RESOURCES' })
      }



      /*
                  setTimeout(() => {
                      _this.setResourceAreaWidth(_this);
                      (this.fullcalendar.getApi() as any).rerenderEvents();
                  }, 100);
      */
      return resources.map(resource => Object.assign({ title: resource.name }, resource));
    }
  }

  getEvents(_this: CheckInCalendarViewNewComponent) {
    return async (info) => {
      let hasEvents = false;
      let events: IEvent[];
      let resourcePersons = {};
      let day = moment(info.start).locale('en').format('YYYY-MM-DD');
      console.log(`Getting bookings from ${day}`);
      let bookings = await _this.api.client().get<IBooking[]>(`/bookings/by-day/${day}`);
      let resourceBookings = await _this.api.client().get<IResourceBooking[]>(`/resource-bookings/by-day/${day}`);
      let resourceAllocations = await _this.api.client().get<any[]>(`/resource-allocations/by-day/${day}`);
      //let calendarEventsWithEvents = [];

      let bookingDictionary: { [bookingId: string]: IBooking } = {};
      for (let booking of bookings) {
        bookingDictionary[booking.id] = booking;
      }

      let resourceBookingDictionary: { [resourceBookingId: string]: IResourceBooking } = {};
      for (let resourceBooking of resourceBookings) {
        resourceBookingDictionary[resourceBooking.id] = resourceBooking;
        if (resourceBooking.eventId)
          hasEvents = true;
      }

      let eventDictionary: {
        [eventId: string]: {
          event: IEvent,
          dateDictionary: { [identifier: string]: IEventDate }
        }
      } = {};
      let eventsWithoutResourcesDictionary: {
        [eventId: string]: {
          event: IEvent,
          dateDictionary: { [identifier: string]: IEventDate }
        }
      } = {};

      // Events with resources
      if (hasEvents) {
        events = await _this.api.client().get<IEvent[]>(`/events/by-day/${day}`);

        for (let event of events) {
          eventDictionary[event.id] = {
            event: event,
            dateDictionary: {}
          }
          for (let eventDate of event.dates) {
            eventDictionary[event.id].dateDictionary[eventDate.identifier] = eventDate;
          }
        }
      }
      // Events without resources
      if (this.globals.userSettings.showEventsWithoutResourcesInCalendar) {
        events = await _this.api.client().get<IEvent[]>(`/events/without-resources/by-day/${day}`);

        for (let event of events) {
          eventsWithoutResourcesDictionary[event.id] = {
            event: event,
            dateDictionary: {}
          }
          for (let eventDate of event.dates) {
            eventsWithoutResourcesDictionary[event.id].dateDictionary[eventDate.identifier] = eventDate;
          }
        }
      }

      let allocationsDictionary: any = {};
      let calendarEvents: any[] = [];

      for (let allocation of resourceAllocations) {
        if (!allocationsDictionary[allocation.bookingId])
          allocationsDictionary[allocation.bookingId] = {}
        if (!allocationsDictionary[allocation.bookingId][allocation.uniquePackageId])
          allocationsDictionary[allocation.bookingId][allocation.uniquePackageId] = {}
        if (!allocationsDictionary[allocation.bookingId][allocation.uniquePackageId][allocation.activityId])
          allocationsDictionary[allocation.bookingId][allocation.uniquePackageId][allocation.activityId] = {}
        if (!allocationsDictionary[allocation.bookingId][allocation.uniquePackageId][allocation.activityId][allocation.resourceId])
          allocationsDictionary[allocation.bookingId][allocation.uniquePackageId][allocation.activityId][allocation.resourceId] = []
        allocationsDictionary[allocation.bookingId][allocation.uniquePackageId][allocation.activityId][allocation.resourceId].push(allocation);
      }

      let countedUniquePackageIds: { [uniquePackageId: string]: { [resourceId: string]: boolean } } = {};
      let countedUnqueResoruceIds: { [resourceBookingId: string]: { [resourceId: string]: boolean } } = {};
      for (let allocation of resourceAllocations) {
        try {
          // Regular bookings
          if (allocation.bookingId) {
            let existingAllocations = allocationsDictionary[allocation.bookingId][allocation.uniquePackageId][allocation.activityId][allocation.resourceId];

            // Check if there is an allocation right before current allocation
            let allocationBefore = existingAllocations.find(a => a.endTime == allocation.startTime && a.persons == allocation.persons);
            // Check if there is an allocation right after current allocation
            let allocationAfter = existingAllocations.find(a => a.startTime == allocation.endTime && a.persons == allocation.persons);

            if (allocationBefore) {
              allocationBefore.endTime = allocation.endTime;
            }
            else if (allocationAfter) {
              allocationAfter.startTime = allocation.startTime;
            }
            else {
              let booking = bookingDictionary[allocation.bookingId];
              let _package = null;
              let activity = null;
              let resource = null;
              let followingAllocations = [];
              for (let _p of booking.packages) {
                for (let _a of _p.activities) {
                  for (let _r of _a.resources) {
                    if (_r.resourceId == allocation.resourceId
                      && _a.activityId == allocation.activityId
                      && _p.uniquePackageId == allocation.uniquePackageId) {
                      _package = _p;
                      activity = _a;
                      resource = _r;

                      // Check if there is an activity following this activity
                      followingAllocations = resourceAllocations.filter(a => {
                        return a.bookingId == booking.id
                          && a.resourceId != _r.resourceId
                          && a.startTime == allocation.endTime
                      }).map(a => {
                        return Object.assign({
                          // This finds the resource of the booking
                          resource: booking.packages.reduce(
                            (res, p) => res
                              || p.activities.reduce((res, act) => res
                                || act.resources.reduce((res, r) => res
                                  || (r.resourceId == a.resourceId ? r : null),
                                  res),
                                res),
                            null)
                        }, a);
                      });

                      let uniqueFollowingAllocations = [];
                      for (let allocation of followingAllocations) {
                        if (!uniqueFollowingAllocations.find(a => a && allocation && a.resourceId == allocation.resourceId))
                          uniqueFollowingAllocations.push(allocation);
                      }
                      followingAllocations = uniqueFollowingAllocations;

                    }
                  }
                }
              }

              let className = followingAllocations.length ? 'has-following' : '';
              if (this.globals.userSettings.calendarPreferences.expandedResources[resource.resourceId])
                className += ' compact';

              let calendarEvent = {
                id: `${allocation.bookingId}_${allocation.packageId}_${allocation.activityId}_${allocation.resourceId}`,
                resourceId: resource.resourceId,
                startTime: allocation.startTime,
                endTime: allocation.endTime,
                start: `${allocation.day} ${allocation.startTime}`,
                end: `${allocation.day} ${allocation.endTime == '00:00' ? '23:59' : allocation.endTime}`,
                booking: booking,
                package: _package,
                activity: activity,
                resource: resource,
                allocation: allocation,
                followingAllocations: followingAllocations,
                className: className
              }
              if (!resourcePersons[resource.resourceId])
                resourcePersons[resource.resourceId] = 0;

              if (!countedUniquePackageIds[allocation.uniquePackageId])
                countedUniquePackageIds[allocation.uniquePackageId] = {};
              if (!countedUniquePackageIds[allocation.uniquePackageId][allocation.resourceId]) {
                resourcePersons[resource.resourceId] += allocation.persons;
                countedUniquePackageIds[allocation.uniquePackageId][allocation.resourceId] = true;
              }



              calendarEvents.push(calendarEvent);
            }
          }

          //added on 2024-07-18 by Filip Åberg
          //add resource bookings allocation to the resourcePersons
          else if (allocation.resourceBookingId) {

            //added on 2024-07-18 by Filip Åberg
            //resouce bookings do not have uniquePackageId but they have resourceBookingId instead
            //so we need to check if the allocation is a resource booking and then add the persons to the resourcePersons
            if (!resourcePersons[allocation.resourceId])
              resourcePersons[allocation.resourceId] = 0;

            if (!countedUnqueResoruceIds[allocation.resourceBookingId]) {
              countedUnqueResoruceIds[allocation.resourceBookingId] = {};
            }

            if (!countedUnqueResoruceIds[allocation.resourceBookingId][allocation.resourceId]) {
              resourcePersons[allocation.resourceId] += allocation.persons;
              countedUnqueResoruceIds[allocation.resourceBookingId][allocation.resourceId] = true;
            }
          }

        }
        catch (error) {
          console.log(`Could not create event from allocation ${JSON.stringify(allocation)}`);
        }
      }

      // Resource bookings
      for (let resourceBooking of resourceBookings) {
        for (let resourceBookingResource of resourceBooking.resources) {
          let event = null;
          let eventDate = null;

          if (resourceBooking.eventId) {
            event = eventDictionary[resourceBooking.eventId].event;
            eventDate = eventDictionary[resourceBooking.eventId].dateDictionary[resourceBooking.eventDateIdentifier];
          }

          let className = event ? 'event-resource-booking' : 'resource-booking';
          if (this.globals.userSettings.calendarPreferences.expandedResources[resourceBookingResource.resourceId])
            className += ' compact';
          let calendarEvent = {
            id: `${resourceBooking.id}_${resourceBookingResource.resourceId}_${resourceBookingResource.uniqueResourceId}`,
            resourceId: resourceBookingResource.resourceId,
            startTime: resourceBookingResource.startTime,
            endTime: resourceBookingResource.endTime,
            start: `${resourceBooking.localDay} ${resourceBookingResource.startTime}`,
            end: `${resourceBooking.localDay} ${resourceBookingResource.endTime == '00:00' ? '23:59' : resourceBookingResource.endTime}`,
            resourceBooking: resourceBooking,
            resourceBookingResource: resourceBookingResource,
            className: className,
            event: event,
            eventDate: eventDate,
            allocation: resourceAllocations.find(a => a.resourceId == resourceBookingResource.resourceId && a.resourceBookingId == resourceBooking.id)
          }



          calendarEvents.push(calendarEvent);

          //  if (calendarEvent.event)
          //      calendarEventsWithEvents.push(calendarEvent);
        }

      }
      // Events without resources
      for (let event of Object.values(eventsWithoutResourcesDictionary)) {
        let eventDate = Object.values(event.dateDictionary).find(d => d.day == day);

        let className = 'event-resource-booking without-resources';
        if (this.globals.userSettings.calendarPreferences.expandedResources['EVENTS_WITHOUT_RESOURCES'])
          className += ' compact';
        let calendarEvent = {
          id: `${event.event.id}_${eventDate.identifier}`,
          resourceId: 'EVENTS_WITHOUT_RESOURCES',
          startTime: eventDate.startTime,
          endTime: eventDate.endTime,
          start: `${eventDate.day} ${eventDate.startTime}`,
          end: `${eventDate.day} ${eventDate.endTime == '00:00' ? '23:59' : eventDate.endTime}`,
          resourceBooking: null,
          resourceBookingResource: null,
          className: className,
          event: event.event,
          eventDate: eventDate,
          allocation: null,
          isEventWithoutResource: true
        }
        calendarEvents.push(calendarEvent);
      }
      /*
                  if (hasEvents) {
                      if (!this.fullcalendar.getApi().getResourceById('EVENTS')) {
                          let name = this.translate.instant('EVENTS');
                          this.fullcalendar.getApi().addResource({
                              id: 'EVENTS',
                              name: name,
                              title: name,
                          } as any);
                      }

                      let addedCalenderEventsWithEvents: { [eventId: string]: boolean } = {};
                      for (let calendarEventWithEvent of calendarEventsWithEvents) {
                          if(!addedCalenderEventsWithEvents[calendarEventWithEvent.eventId]){
                              calendarEvents.push(Object.assign({}, calendarEventWithEvent, {
                                  resourceId: 'EVENTS',

                              }))
                              addedCalenderEventsWithEvents[calendarEventWithEvent.eventId] = true;
                          }

                      }

                  } */

      this.resourcePersons = resourcePersons;
      //   (this.fullcalendar.getApi() as any).rerenderResources();
      console.log(this.resourcePersons);
      return calendarEvents;
    };
  }



  getBookingIconsHtml(data) {
    return this.sanitizer.bypassSecurityTrustHtml(this.utility.getBookingIconsHtml(data));
  }


  getResourceBookingCalendarEventHtml(event, resourceBooking: IResourceBooking, resourceBookingResource: IResourceBookingResource) {
    return `
            <div class="activity ${event.extendedProps.allocation && event.extendedProps.allocation.isLocked ? 'blocked' : ''}">
                <div class="ribbon" style="background:${this.resources && this.resources[resourceBookingResource.resourceId] ? this.resources[resourceBookingResource.resourceId].color : ''}"></div>
                <div class="title">${resourceBooking.customer.firstName} ${resourceBooking.customer.lastName}</div>
                <div class="time">${resourceBookingResource.name}</div>
                <hr/>
                <div class="icons">${this.getBookingIconsHtml(event.extendedProps)}</div>
            </div>`;
  }

  getEventResourceBookingCalendarEventHtml(e, event: IEvent, eventDate: IEventDate, resourceBooking: IResourceBooking, resourceBookingResource: IResourceBookingResource) {
    return `
            <div class="activity" style="background-color: ${event.color}">
                <div class="ribbon" style="background:${this.resources && resourceBookingResource && this.resources[resourceBookingResource.resourceId] ? this.resources[resourceBookingResource.resourceId].color : ''}"></div>
                <div class="title">${event.name}</div>
                <div class="time">${resourceBookingResource ? resourceBookingResource.name : ''}</div>
                <hr/>
                <div class="icons">${this.getBookingIconsHtml(e.extendedProps)}</div>
            </div>`;
  }


  getAddonsHtml(_package) {
    if (_package.addOns && _package.addOns.length) {
      let html = `<div class="add-ons">
                <b>${this.translate.instant('ADD_ONS')}</b>
                <table>`;
      for (let addOn of _package.addOns) {
        html += `<tr>
                    <td>${addOn.quantity}x ${addOn.name} á ${this.currency.format(addOn.unitPrice.withVat)}</td>
                </tr>`;
      }
      html += '</table></div>';
      return html;
    }
    else {
      return '';
    }
  }

  /*
  async eventRender(info) {
    let viewType = this.fullcalendar.getApi().view.type;
    let element: HTMLElement = info.el;
    let e = info.event;
    let booking = e.extendedProps.booking;
    let resourceBooking = e.extendedProps.resourceBooking;
    let resourceBookingResource = e.extendedProps.resourceBookingResource;
    let _package = e.extendedProps.package;
    let resource = e.extendedProps.resource;
    let allocation = e.extendedProps.allocation;
    let followingAllocations = e.extendedProps.followingAllocations;
    let event = e.extendedProps.event;
    let eventDate = e.extendedProps.eventDate;

    if (booking)
      element.innerHTML = this.getBookingCalendarEventHtml(e, booking, _package, resource, allocation, followingAllocations);
    else if (resourceBooking) {
      if (event && eventDate) {
        element.innerHTML = this.getEventResourceBookingCalendarEventHtml(e, event, eventDate, resourceBooking, resourceBookingResource);
      }
      else {
        element.innerHTML = this.getResourceBookingCalendarEventHtml(e, resourceBooking, resourceBookingResource);
      }
    } else if (e.extendedProps.isEventWithoutResource) {
      element.innerHTML = this.getEventResourceBookingCalendarEventHtml(e, event, eventDate, resourceBooking, resourceBookingResource);
    }


    element.addEventListener('click', () => {
      this.eventClicked(e);
    });
  }
*/

  /*
    async resourceRender(info) {
      console.log('Rendering resource');
      let resource = info.resource;
      let element: HTMLElement = info.el;

      element.querySelector('.fc-cell-content').innerHTML = `<div class="resource">
              <span class="name">${resource.extendedProps.name}</span>
              <div class="color" style="background:${resource.extendedProps.color}"></div>
              <div class="persons">${this.resourcePersons[resource.id] ? this.resourcePersons[resource.id] : ''}</div>
              </div>`;

    }
  */

  async eventClicked(event) {
    this.bookingClicked.emit(event.extendedProps);
  }


  async setupCalendar() {
    this.updateDate();
    this.fullcalendar.getApi().render();

    this.setCalendarHeight();
    this.fullcalendar.getApi().render();
    this.fullcalendar.getApi().setOption('scrollTime', '09:00:00');
    this.translate.get('RESOURCES').subscribe((text) => { (this.fullcalendar.getApi() as any).setOption('resourceLabelText', text) });

    (this.fullcalendar.getApi() as any).on('viewSkeletonRender', (() => {
      console.log('Rendered');

    }));

  }


  async ngAfterViewInit() {
    let setupUserSettings = async (userSettings) => {
      if (userSettings.showPackageColorsInCalendar) {
        let packages = await this.api.client().get<IPackage[]>('/packages');
        this.packages = {};
        for (let _package of packages) {
          this.packages[_package.id] = _package;
        }
      }

      if (this.fullcalendar) {
        this.fullcalendar.getApi().setOption('slotDuration', userSettings.calendarInterval);
        this.fullcalendar.getApi().setOption('slotLabelInterval', userSettings.calendarLabelInterval);
        this.fullcalendar.getApi().scrollToTime(moment().format('HH') + ':00');
      }
    };
    if (this.globals.userSettings)
      setupUserSettings(this.globals.userSettings);
    else
      this.globals.userSettingsReceived.subscribe(setupUserSettings);
    this.setupCalendar();
    this.goToCurrentTime();
    setTimeout(() => {
      this.setResourceAreaWidth(this);
    }, 1000);
  }

  goToCurrentTime() {
    if (this.fullcalendar) {
      let time = moment().format('HH') + ':00:00';
      this.fullcalendar.getApi().scrollToTime(time);
    }
  }


  onLoading(_this: CheckInCalendarViewNewComponent) {
    return (loading) => {
      console.log(`Loading: ${loading}`);
      if (!loading)
        _this.setResourceAreaWidth(_this);
    }
  }



  async ngOnInit() {
    this.calendarOptions.defaultDate = this.selectedDate;
  }
}
